/**
 * Overwrite default :focus-visible color
 */
:focus-visible {
  outline-color: rgb(37 99 235);
}

/**
 * GitHub Label styles
 */
.dynamic-label {
  --perceived-lightness: calc(
    (
        (var(--label-r) * 0.2126) + (var(--label-g) * 0.7152) +
          (var(--label-b) * 0.0722)
      ) / 255
  );
  --lightness-switch: max(
    0,
    min(
      calc((var(--perceived-lightness) - var(--lightness-threshold)) * -1000),
      1
    )
  );

  --lightness-threshold: 0.453;
  --border-threshold: 0.96;
  --border-alpha: max(
    0,
    min(calc((var(--perceived-lightness) - var(--border-threshold)) * 100), 1)
  );
  color: hsl(0deg, 0%, calc(var(--lightness-switch) * 100%));
  background: rgb(var(--label-r), var(--label-g), var(--label-b));
  border-color: hsla(
    var(--label-h),
    calc(var(--label-s) * 1%),
    calc((var(--label-l) - 25) * 1%),
    var(--border-alpha)
  );
}
.dark .dynamic-label {
  --lightness-threshold: 0.6;
  --background-alpha: 0.18;
  --border-alpha: 0.3;
  --lighten-by: calc(
    ((var(--lightness-threshold) - var(--perceived-lightness)) * 100) *
      var(--lightness-switch)
  );
  color: hsl(
    var(--label-h),
    calc(var(--label-s) * 1%),
    calc((var(--label-l) + var(--lighten-by)) * 1%)
  );
  background: rgba(
    var(--label-r),
    var(--label-g),
    var(--label-b),
    var(--background-alpha)
  );
  border-color: hsla(
    var(--label-h),
    calc(var(--label-s) * 1%),
    calc((var(--label-l) + var(--lighten-by)) * 1%),
    var(--border-alpha)
  );
}

/**
 * Fixing the prose styling for checkbox elements based on Flowbite styles.
 */
.prose input {
  margin: 0;
  position: relative;
  color: #3b82f6;
  background-color: #f4f4f5;
  border-color: #d4d4d8;
  border-radius: 0.25rem;
}
.dark .prose input {
  --tw-ring-offset-color: #27272a;
  background-color: #3f3f46;
  border-color: #52525b;
}

/**
 * Code view helpers
 */
.diff-change-marker::before {
  position: absolute;
  top: 1px;
  left: 8px;
  padding-right: 8px;
  content: attr(data-code-marker);
}

.code-block *::selection {
  background: var(--selection-bg-color);
}

[data-view="split"][data-selection-starting-side="left"] [data-side="right"] {
  user-select: none;
}
[data-view="split"][data-selection-starting-side="right"] [data-side="left"] {
  user-select: none;
}

/**
 * Extracted from Refined GitHub, used to render whitespace characters
 */
.code-block [data-whitespace="space"] {
  background-image: url('data:image/svg+xml,%3Csvg preserveAspectRatio="xMinYMid meet" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M4.5 11C4.5 10.1716 5.17157 9.5 6 9.5C6.82843 9.5 7.5 10.1716 7.5 11C7.5 11.8284 6.82843 12.5 6 12.5C5.17157 12.5 4.5 11.8284 4.5 11Z" fill="rgba%28128, 128, 128, 50%25%29"/%3E%3C/svg%3E');
  background-size: 1ch 1.25em;
}
.code-block [data-whitespace="tab"] {
  background-image: url('data:image/svg+xml,%3Csvg preserveAspectRatio="xMinYMid meet" viewBox="0 0 12 24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M9.5 10.44L6.62 8.12L7.32 7.26L12.04 11V11.44L7.28 14.9L6.62 13.9L9.48 11.78H0V10.44H9.5Z" fill="rgba%28128, 128, 128, 50%25%29"/%3E%3C/svg%3E');
  background-size: calc(var(--tab-size) * 1ch) 1.25em;
  background-position: 2px center;
}
.code-block [data-whitespace] {
  line-height: 1.333333em;
  background-clip: border-box;
  background-repeat: repeat-x;
  background-position: left center;
}

/**
 * Flowbite form styles
 *
 * See https://github.com/themesberg/flowbite/blob/73e187e7999f7b503f72be758fff3e57784694f5/plugin-windicss.js#L89
 */

[multiple],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #71717a;
  border-radius: 0;
  border-width: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0.75rem;
}

[multiple]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
select:focus,
textarea:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  border-color: #1c64f2;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #71717a;
  opacity: 1;
}

input::placeholder,
textarea::placeholder {
  color: #71717a;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
}

select:not([size]) {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%2#71717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m6 8 4 4 4-4'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

[multiple] {
  background-image: none;
  background-position: 0 0;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}

[type="checkbox"],
[type="radio"] {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  background-origin: border-box;
  border-color: #71717a;
  border-width: 1px;
  color: #1c64f2;
  display: inline-block;
  flex-shrink: 0;
  height: 1rem;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  vertical-align: middle;
  width: 1rem;
}

[type="checkbox"] {
  border-radius: 0;
}

[type="radio"] {
  border-radius: 100%;
}

[type="checkbox"]:focus,
[type="radio"]:focus {
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #1c64f2;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.dark [type="checkbox"]:checked,
.dark [type="radio"]:checked,
[type="checkbox"]:checked,
[type="radio"]:checked {
  background-color: currentColor;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}

[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.207 4.793a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L6.5 9.086l4.293-4.293a1 1 0 0 1 1.414 0z'/%3E%3C/svg%3E");
}

[type="radio"]:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
}

[type="checkbox"]:indeterminate {
  background-color: currentColor;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3E%3C/svg%3E");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-color: transparent;
}

[type="checkbox"]:indeterminate:focus,
[type="checkbox"]:indeterminate:hover {
  background-color: currentColor;
  border-color: transparent;
}

[type="file"] {
  background: unset;
  border-color: inherit;
  border-radius: 0;
  border-width: 0;
  font-size: unset;
  line-height: inherit;
  padding: 0;
}

[type="file"]:focus {
  outline: 1px auto inherit;
}

input[type="file"]::file-selector-button {
  -webkit-margin-start: -1rem;
  -webkit-margin-end: 1rem;
  background: #27272a;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  margin-inline-end: 1rem;
  margin-inline-start: -1rem;
  padding: 0.625rem 1rem 0.625rem 2rem;
}

input[type="file"]::file-selector-button:hover {
  background: #52525b;
}

.dark input[type="file"]::file-selector-button {
  background: #4b5563;
  color: #fff;
}

.dark input[type="file"]::file-selector-button:hover {
  background: #71717a;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

input[type="range"]:disabled::-webkit-slider-thumb {
  background: #a1a1aa;
}

.dark input[type="range"]:disabled::-webkit-slider-thumb {
  background: #71717a;
}

input[type="range"]:focus::-webkit-slider-thumb {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  --tw-ring-opacity: 1px;
  --tw-ring-color: rgb(164 202 254 / var(--tw-ring-opacity));
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  outline: 2px solid transparent;
  outline-offset: 2px;
}

input[type="range"]::-moz-range-thumb {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #1c64f2;
  border: 0;
  border-radius: 9999px;
  cursor: pointer;
  height: 1.25rem;
  width: 1.25rem;
}

input[type="range"]:disabled::-moz-range-thumb {
  background: #a1a1aa;
}

.dark input[type="range"]:disabled::-moz-range-thumb {
  background: #71717a;
}

input[type="range"]::-moz-range-progress {
  background: #3f83f8;
}

input[type="range"]::-ms-fill-lower {
  background: #3f83f8;
}

input[type="range"].range-sm::-webkit-slider-thumb {
  height: 1rem;
  width: 1rem;
}

input[type="range"].range-lg::-webkit-slider-thumb {
  height: 1.5rem;
  width: 1.5rem;
}

input[type="range"].range-sm::-moz-range-thumb {
  height: 1rem;
  width: 1rem;
}

input[type="range"].range-lg::-moz-range-thumb {
  height: 1.5rem;
  width: 1.5rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
